import React, { useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { graphql } from "gatsby"
import { Controller, useForm } from "react-hook-form"
import { AiOutlineCheck } from "react-icons/ai"
import { BiError } from "react-icons/bi"
import { BsWhatsapp } from "react-icons/bs"
import Flag from "react-world-flags"
import * as Yup from "yup"
import CountryComboBox from "../components/CountryComboBox"
import Input from "../components/Input"
import { Navigator } from "../components/LanguageHelpers/Navigator"
import Layout from "../components/layout"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Contact = ({
  pageContext,
  data: {
    datoCmsContactPage: { title, subtitle, seo },
    allDatoCmsDipendenti,
    menuString,
    allDatoCmsPrivacy,
  },
}) => {
  const [submitStatus, setSubmitStatus] = useState(false)
  const [submitText, setSubmitText] = useState("")
  const [submitSuccess, setSubmitSuccess] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState()
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const validationScheme = Yup.object({
    Company: Yup.string().required(),
    Email: Yup.string().email().required(),
    Country: Yup.string().required(),
    Phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
    Message: Yup.string().required(),
    Consent: Yup.boolean()
      .required()
      .oneOf([true], menuString.nodes[0].fieldRequired),
  })

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationScheme),
    mode: "onChange",
    defaultValues: {
      Company: "",
      Email: "",
      Country: "",
      Phone: "",
      Message: "",
      Consent: false,
    },
  })
  const onSubmit = async (data, e) => {
    setSubmitStatus(true)
    setSubmitText("Submitting..")
    e.preventDefault()
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "Contact",
        subject: `${data.Company} | ${data.Country} ${selectedCountry.phone} - Contact`,
        ...data,
      }),
    })
      .then(() => {
        setSubmitSuccess(true)
        setSubmitText(
          "Thank you for reaching out, we'll be contacting you shortly"
        )
        reset()
      })
      .catch(error => {
        setSubmitSuccess(false)
        setSubmitText(
          "There was an error with your submission, please email us using the address below."
        )
      })
  }
  const handleSuccess = () => {
    setSubmitStatus(false)
    setSubmitSuccess(null)
  }
  const handleFailed = () => {
    setSubmitStatus(false)
    setSubmitSuccess(null)
  }
  const getFlag = dipendente => {
    const flags = dipendente.language
    const Newflags = flags
      .replace(/"/g, "")
      .replace("[", "")
      .replace("]", "")
      .replace(/\n/g, "")
      .replace(/ /g, "")
      .replace(/en/g, "gb")
    const flagsList = Newflags.split(",")

    return flagsList
  }
  const schema = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    name: "MoviLift",
    url: "https://www.movilift.com",
    description: seo?.seo?.seoDescription,
    logo: seo?.seo?.image?.seoImageUrl,
    sameAs: [
      "https://www.facebook.com/movilift/",
      "https://www.instagram.com/moviliftitalia/",
    ],
  }

  return (
    <Layout
      pageData={pageContext}
      seoTitle={seo?.seoTitle}
      seoDescription={seo?.seoDescription}
      seoImage={seo?.image?.seoImageUrl}
      schemaMarkup={schema}
    >
      <div className="mx-auto flex w-full max-w-screen-xl">
        <div className="flex w-full flex-col gap-4 md:px-4">
          <div className="flex items-center justify-center gap-4 py-4">
            <div className="gradient-primary  flex h-full w-full flex-col  items-start justify-between gap-4 rounded-lg p-4 text-white shadow-lg  md:p-10">
              <div className="flex w-full flex-col gap-4">
                <h1 className="white text-5xl font-bold md:text-6xl">
                  {title}
                </h1>
                <p className="text-xl">{subtitle}</p>
              </div>
              <div className="w-full gap-4 ">
                {submitStatus ? (
                  submitSuccess !== null ? (
                    submitSuccess ? (
                      <div className="flex h-full w-full flex-col items-center justify-center gap-8 ">
                        <AiOutlineCheck size={60} color="#ffffff" />
                        <p>{submitText}</p>
                        <button
                          className="rounded-full bg-white px-5 py-2.5 font-semibold text-primary-600"
                          onClick={handleSuccess}
                        >
                          Contact us again
                        </button>
                      </div>
                    ) : (
                      <div className="flex h-full w-full  flex-col items-center justify-center gap-8 ">
                        <BiError size={60} color="#ffffff" />
                        <p>{submitText}</p>
                        <button
                          className="rounded-full bg-white px-5 py-2.5 font-semibold text-primary-600"
                          onClick={handleFailed}
                        >
                          Retry
                        </button>
                      </div>
                    )
                  ) : (
                    <div
                      className="m-8 flex flex-col items-center justify-center text-center"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "2rem",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <svg
                        className="h-20 w-20 animate-spin fill-white text-gray-300"
                        viewBox=" 0 0 100 100"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <p>{submitText}</p>
                    </div>
                  )
                ) : (
                  <form
                    className=" flex w-full  flex-col justify-start gap-4 text-black "
                    name="Contact"
                    method="POST"
                    data-netlify="true"
                    netlify-honeypot="BotField"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    {" "}
                    <div className="mb-4 grid gap-6 md:grid-cols-2">
                      <input
                        type="hidden"
                        name="subject"
                        value="{{Company}} contact | {{Country}}"
                      />
                      <input type="hidden" name="form-name" value="Elevator" />
                      <p style={{ display: "none" }}>
                        <label>
                          Don’t fill this out if you expect to hear from us!
                          <input name="BotField" value="" readOnly />
                        </label>
                      </p>

                      <Input
                        name="Company"
                        label={menuString.nodes[0].companyName}
                        errors={errors.Company}
                        errorsMessage={menuString.nodes[0].fieldRequired}
                        register={register}
                      />

                      <Input
                        name="Email"
                        label={menuString.nodes[0].email}
                        errors={errors.Email}
                        errorsMessage={menuString.nodes[0].fieldRequired}
                        register={register}
                      />
                      <div>
                        <label
                          className="mb-2 block text-sm font-medium text-white "
                          for="message"
                        >
                          {menuString.nodes[0].country}
                        </label>
                        <Controller
                          name="Country"
                          control={control}
                          render={({ field: { onChange } }) => (
                            <CountryComboBox
                              placeholder={menuString.nodes[0].country}
                              selectedCountry={selectedCountry}
                              setSelectedCountry={e => {
                                onChange(e.name)
                                setSelectedCountry(e)
                              }}
                            />
                          )}
                        />
                        {errors.Country && (
                          <p className="mt-2 block text-sm font-medium text-red-500">
                            {menuString.nodes[0].fieldRequired}
                          </p>
                        )}
                      </div>
                      <Input
                        name="Phone"
                        label={menuString.nodes[0].phoneNumber}
                        errors={errors.Phone}
                        errorsMessage={menuString.nodes[0].fieldRequired}
                        register={register}
                      />
                      <div className="md:col-span-2">
                        <label
                          className="mb-2 block text-sm font-medium text-white "
                          for="message"
                        >
                          {menuString.nodes[0].message}
                        </label>
                        <textarea
                          className="block w-full resize-none rounded-lg border border-gray-300 bg-white p-4 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                          name="message"
                          placeholder={menuString.nodes[0].message}
                          maxLength="5000"
                          {...register("Message")}
                        ></textarea>{" "}
                        {errors.Message && (
                          <p className="mt-2 block text-sm font-medium text-red-500">
                            {menuString.nodes[0].fieldRequired}
                          </p>
                        )}
                      </div>
                      <div className="flex items-center gap-4 md:col-span-2">
                        <input
                          type="checkbox"
                          id="Consent"
                          name="Consent"
                          {...register("Consent")}
                        />
                        <label className="text-sm font-semibold text-white">
                          <Navigator
                            recordId={allDatoCmsPrivacy.nodes[0].id}
                            target="_blank"
                          >
                            {menuString.nodes[0].consentText}
                          </Navigator>
                        </label>
                      </div>
                      {errors.Consent && (
                        <p className="mt-2 block text-sm font-medium text-red-500">
                          {menuString.nodes[0].fieldRequired}
                        </p>
                      )}
                    </div>
                    <button
                      className="mx-auto w-full rounded-lg bg-white px-2.5 py-4 text-lg font-semibold text-primary-600 shadow hover:opacity-90 md:w-1/3     "
                      onClick={handleSubmit(onSubmit)}
                    >
                      {menuString.nodes[0].send}
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>

          <div className="my-4 flex flex-col gap-4 px-4">
            <h2 className="text-3xl font-bold text-primary-600">
              {menuString.nodes[0].ourTeam}
            </h2>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 ">
              {allDatoCmsDipendenti.nodes.map(dipendente => (
                <div className="flex flex-col gap-4 rounded-lg border border-gray-200 bg-white p-6 shadow-md transition hover:bg-gray-100">
                  <div className="flex w-full flex-col justify-between gap-2 md:flex-row">
                    <span className="font-semibold">{dipendente.nome}</span>
                    <div style={{ display: "flex", gap: "10px" }}>
                      {getFlag(dipendente).map(flagCode => (
                        <Flag code={flagCode} width="24" />
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <a
                      className="hover:underline"
                      href={`mailto:${dipendente.email}`}
                    >
                      {dipendente.email}
                    </a>
                    <div className="flex gap-4">
                      {dipendente.phone && (
                        <>
                          <a
                            className="hover:underline"
                            target={"_blank"}
                            rel={"noreferrer noopener"}
                            href={`https://wa.me/${dipendente.phone}?text=Hello%20${dipendente.nome}%20I%27m%20writing%20you%20from%20movilift.com`}
                          >
                            <BsWhatsapp size={22} fill={"green"} />
                          </a>
                          <a
                            className="hover:underline"
                            href={`tel:${dipendente.phone}`}
                          >
                            +{dipendente.phone}
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query ContactPageQuery($locale: String!) {
    datoCmsContactPage(locale: { eq: $locale }) {
      locale
      id: originalId
      slug
      title
      subtitle
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
    }
    allDatoCmsPrivacy {
      nodes {
        id: originalId
        locale
        slug
      }
    }
    allDatoCmsDipendenti(
      filter: { locale: { eq: $locale } }
      sort: { fields: position }
    ) {
      nodes {
        nome
        phone
        email
        language
        role
      }
    }
    menuString: allDatoCmsMiscString(filter: { locale: { eq: $locale } }) {
      nodes {
        companyName
        email
        phoneNumber
        message
        country
        send
        ourTeam
        fieldRequired
        consentText
      }
    }
  }
`
